import request from '~/common/utils/request'

export function sendVerifyMobile (data) {
  // return request({
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   url: '/user/social/mobile',
  //   method: 'put',
  //   data
  // })
}

export function updateMobile (data) {
  return request({
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/user/social/mobile',
    method: 'put',
    data
  })
}

export function verifyOtp (data) {
  return request({
    url: '/user/verify/otp',
    method: 'post',
    data
  })
}

export function resendVerify (data) {
  return request({
    url: '/user/resend/otp',
    method: 'post'
  })
}

export function sendVerifyEmail (data) {
  return request({
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/user/social/email',
    method: 'put',
    data
  })
}

export function verifyEmailOtp (data) {
  return request({
    url: '/user/verify/email-otp',
    method: 'post',
    data
  })
}

export function resendVerifyEmailOtp (data) {
  return request({
    url: '/user/resend/email-otp',
    method: 'post',
    data
  })
}
